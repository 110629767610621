@import "../reset";
@import "../variables";
@import "../functions";

.accordion_title {
  @include flex(row, center, space-between);
  padding: 10px 16px;
  border-bottom: 1px solid #068068;
  color: #068068;
  background: #fff;
  font-size: 16px;

  & > h2 {
  }

  & > button {
    background: none;
    color: inherit;
    transition: transform .2s;
  }

  & > svg {
  }

}


.accordion_content {
  overflow: hidden;
  max-height: 0;
}

.is_open {
  max-height: 999999vh !important;
}

.btn_reverse {
  transform: rotate(-180deg);
}
