@import "../reset";
@import "../variables";
@import "../functions";

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: calc(100 * var(--vh, 1vh));
  z-index: 999;
  @include flex(row, center, center);
}


.modal_black {
  background: rgba(0, 0, 0, .8);
}
.modal_white {
  background: rgba(255, 255, 255, .8);
}

.modal_inner {
  position: relative;
  width: 85%;
  background: $white;
}

.modal_inner_transparent {
  position: relative;
  width: 100%;
  height: 100%;
  background: transparent;
}

.modal_content {
  padding: 25px 15px;
  font-size: 16px;
  text-align: center;

  & > a {
    margin-top: 20px;
    @include flex(row, center, center);
    width: 100%;
  }
}

.modal_content_center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
