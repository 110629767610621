@import "../reset";
@import "../variables";
@import "../functions";

.member_list {
  background: #fff;

  & > li {
    padding: 7.5px 17px 6.5px;
    border-bottom: 1px solid $gray;

    & > a {
      @include flex(row, center, flex-start, nowrap);
      color: $black;
      font-size: 18px;

      & > p {
        font-size: 18px;
      }

      & > img {
        margin-right: 10px;
        max-width: 52px;
        border-radius: 50%;
        box-shadow: 0 2px 4px rgba(0, 0, 0, .09);
      }

      & > svg {
        margin-left: auto;
      }

    }

  }

}

.btn_exchange {
  position: fixed;
  bottom: 165px;
  right: 20px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: $green;
  color: $white;
  z-index: 10;
}

.fixed_bottom {
  position: fixed;
  bottom: 0;
  background: #F7F7F7;
  height: auto;
}

/* thread */
.thread_content {
  overflow: hidden;
  max-height: 0;

  & > li {
    padding: 15px 0 0 30px;
    border-top: 1px solid $gray;
    border-bottom: 1px solid $gray;
  }

}

.is_open {
  max-height: 999999vh !important;
}


.message_list_wrapper {
  margin-bottom: #{$h-input}px;
}

.message_list_wrapper_video {
  margin-top: calc(#{$h-header}px + #{$h-iframe}px)
}

.message_list_wrapper_video_fixed {
  margin-top: calc(#{$h-header}px + #{$h-iframe}px + 70px)
}

.message_list {
  background: #fff;

  & > li {
    padding: 10px;

    word-break: break-all;
    &:not(:last-child) {
      border-bottom: 1px solid $gray;
    }

    & > a {
      color: $black;
    }

  }

}

.message_list_reply {

  & > li {
    padding: 10px 10px 10px 20px;

    &:not(:last-child) {
      border-bottom: 1px solid $gray;
    }

  }

}

.is_liked {
  color: #EA669E !important;
}

.message_info {
  @include flex(row, center, flex-start, nowrap);

  > p {
    font-size: 15px;
    font-weight: bold;
    margin-right: 5px;
  }

  > span {
    font-size: 10px;
    color: #90A09D;
  }

  > svg {
    margin-left: auto;
    color: $gray;
  }
}

.message_content {
  font-size: 15px;
  margin: 10px auto 5px;
  line-height: 1.4;

  & > div {
    word-break: break-word;

    & > pre {
      white-space: break-spaces;
      line-height: 1.35;
    }

  }
}

/* リプライ・いいね・通報 */
.message_actions {
  @include flex(row, center, center);

  & > div {
    @include flex(row, flex-start, flex-end);
    margin-bottom: 3px;

    & > span {
      font-size: 14px;
      @include flex(row, center);
    }

  }
}

.message_actions_left {
  margin-right: auto;
}

.message_actions_right {
  margin-left: auto;
}

.message_actions_reply {
  color: #767676;
  margin-right: 22px;
  & > svg {
    color: #B5B5B5;
    margin-right: 3px;
  }
}

.message_actions_favorite {
  color: #767676;
  button {
    height: 12px;
  }
  svg {
    margin-right: 3px;
    color: #B5B5B5;
  }
}

.message_actions_report {
  color: #B5B5B5;

  svg {
    margin-right: 3px;
    color: #B5B5B5;
  }

  & > :not(:first-child) {
    margin-left: .8em;
  }
}

.message_actions_solid {
  span {

    &:nth-of-type(1) {
      color: $light-gray;
    }

  }

}

.icon_reply {
  color: $light-gray;
  margin-right: 5px;
}


/* 通報 */

.report {
  display: none;
  position: fixed;
  bottom: 0;
  height: calc(100 * var(--vh, 1vh) - #{$h-header}px);
  max-height: 100%;
  width: 100vw;
  background: rgba(0, 0, 0, .85);
  z-index: 1000;
  border-radius: 20px 20px 0 0;
}

.report_inner {
  @include flex(column, center, space-between, nowrap);
  //width: 100%;
  height: 100%;
  padding: 46px 30px 52px;
}

.report_top {
  @include flex(column, flex-start, center);
  width: 100%;

  & > p {
    color: $white;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 17px;

    &.report_description {
      font-size: 18px;
      font-weight: normal;
      margin-top: 18px;
    }
  }

  & > textarea {
    width: 100%;
    height: 160px;
    padding: 19px 14px;
    font-size: 16px;
    outline: none;
    &::placeholder {
      color: #B5B5B5;
    }

  }

}

.report_bottom {
  @include flex(column, center, center);
  width: 100%;
  padding-top: 24px;

  & > button {
    &:first-child {
      margin-bottom: 15px;
    }
  }

}

.report_active {
  @include flex(row, flex-end, center, nowrap);
}


/* 定点カメラ系 */
.camera_container {
  position: fixed;
  top: #{$h-header}px;
  left: 0;
  height: calc(100 * var(--vh, 1vh) - #{$h-header}px);
  width: 100%;
  background: #EDFCF9;
  z-index: 998;
}

.camera_container_removed {
  height: auto;
  width: 100%;
}

.camera_logo {
  object-fit: cover;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.camera_caution {
  height: 100%;
  padding: 12px 0;
}

.camera_caution_content {
  padding: 0 16px;
  margin: 11px auto 0;
  font-size: 14px;
  line-height: 1.5;
  height: calc(100% - #{$h-header}px - 42px);
  overflow: scroll;

  strong {
    font-weight: bold;
  }
  & > div {
    &:not(:nth-child(2)) {
      margin-bottom: 15px;
    }
    &:nth-child(2) {
      border-top: 1px solid $gray;
      padding-top: 15px;
    }
  }
}

.camera_caution_content_video {
  height: calc(100% - #{$h-header}px - 42px - #{$h-iframe}px);
}

// プレイヤー固定メッセージの高さを足した
.camera_caution_content_video_fixed_message {
  height: calc(100% - #{$h-header}px - 42px - #{$h-iframe}px - 70px) !important;
}

.camera_caution_heading {
  padding: 0 16px;
  height: 30px;
  @include flex(row, center, flex-start);
  & > p {
    font-size: 16px;
    font-weight: bold;
  }

  & > img {
    width: 30px;
    height: 30px;
    object-fit: cover;
    margin-right: 10px;
  }

}

.camera_caution_bottom {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 80px;
  width: 100%;
  @include flex(column, center, center);
  & > div {
    margin-bottom: 18px;
  }
}

.camera_caution_remove {
  display: block;
  margin: 0 auto;
  padding: 9px 13px;
  width: 252px;
  font-size: 14px;
  color: #7B7B7B;
  background: transparent;
  border: 1px solid #C6C6C6;
  border-radius: 20px;

  & > svg {
    color: #7B7B7B;
    margin-right: 10px;
  }
}

.camera_caution_invalid_user {
  padding: 5px;
  margin-top: 17px;
  color: #651515;
  border: 1px solid #651515;
  font-size: 14px;
}

.camera_caution_removed {
  display: none;
}

.video_container {
  width: 100%;
  z-index: 90;
}

.video {
  position: relative;
  width: 100%;
  height: 150px;
  overflow: hidden;
  background: #363636;
  @include flex(row, center, center);
  & > div {
    position: absolute;
    padding-top: 0 !important;
    width: 100% !important;
    height: 100% !important;
  }
}

.video_poster {
  @include flex(column, center, center);
  background: $white;
  width: 100%;
  height: 150px;

  & > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.video_fixed_message {
  max-height: 70px;
  overflow: scroll;
  padding: 8px 16px;
  background: #EDFCF9;
  font-size: 12px;
  line-height: 1.25;
}
