/* breakpoint */
$breakpoints: (
        "xs": "screen and (max-width: 374px)",
        "sm": "screen and (max-width: 1200px)",
  //"tb": "screen and (max-width: 1024px)",
        "lg": "screen and (min-width: 1201px)",
) !default;

// variables
$spDesignWidth: 375;
$pcDesignWidth: 1400;
$font-default: "Noto Sans JP", Hiragino Kaku Gothic ProN, Arial, Meiryo, BlinkMacSystemFont, "Helvetica Neue", sans-serif;
$font-size-default: 2rem;
$line-hight-default: 1.8;

$duration: .3s;

// colors
$white: #fff;
$black: #252B2A;
$gray: #EDEDED;
$green: #13B990;
$light-gray: #838383;
$light-blue: #F7FCFC;

// commons
$w-aside: 83;
$h-header: 70;
$h-input: 60;
$h-iframe: 150;
