@import "../reset";
@import "../variables";
@import "../functions";

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 70px;
  @include flex(row, center, flex-start, nowrap);
  font-size: 20px;
  padding: 15px 10px;
  background: $light-blue;

  & > svg {
    color: $black;
  }

}

.headerContent {
  width: 100%;
  @include flex(row, center, flex-start, nowrap);
}

.headerLeftContent {
  @include flex(row, center, center, nowrap);
  margin-left: 35px;

  & > img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .09);
  }

  & > p {
    font-size: 16px;
  }

  & > img, & > svg {
    margin-right: 10px;
  }

}

.headerRightContent {
  position: absolute;
  right:0;
  display: flex;
  align-items: center;

  & > * {
    display: flex;
    justify-content: center;
    width: 40px !important;

  }

}

.headerBtnBack {
  position: absolute;
  left: 0;
  display: flex;
  justify-content: center;
  width: 40px;
}

.headerInner {
  width: 100%;
  @include flex(row, center, flex-start);
}

.headerInnerMarginLeft {
  margin-left: 60px;
}
