@import "../reset";
@import "../variables";
@import "../functions";

.aside {
  position: fixed;
  top: 0;
  left: 0;
  background: #F7FCFC;
  height: 100vh;
  width: 83px;
  padding: 13px;

  ul {

    li {
      height: 72px;

      a {
        display: block;
        border: 2px solid transparent;
        transition: border $duration;
        border-radius: 50%;
        overflow: hidden;
        box-shadow: 0 2px 4px rgba(0, 0, 0, .09);

        & > img {
          width: 53px;
          height: 52px;
          object-fit: cover;
        }

      }

    }
  }
}

.artist_names {
  padding: 4px 0 0 20px;
  ul {
    li {
      display: flex;
      align-items: center;
      height: 72px;
      font-size: 16px;
      border-bottom: 1px solid #EDEDED;
      a {
        display: flex;
        align-items: center;
        height: 100%;
        width: 100%;
      }
    }
  }
}
