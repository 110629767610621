@import "../reset";
@import "../variables";
@import "../functions";

.edit_caution_list {
  color: #7B7B7B;
  font-size: 12px;
  margin: 0 auto;
  width: 332px;

  & > li {
    line-height: 1.6;
    &:before {
      content: '・';
    }
    & > a {
      color: #068068;
    }
  }

}

.edit_btn {
  @include flex(row, center, center);
  margin: 34px auto 0;
  min-width: 120px;
  padding: 10px 0;
  color: $white;
  background: #1DDAB6;
  border-radius: 8px;
  font-size: 16px;
  line-height: 1;
  &:disabled {
    background: #F7F7F7;
    color: #B5B5B5;
  }
}

.edit_input_area {
  padding: 42px 30px 32px;
  margin: 0 auto;
  width: 100%;
}
