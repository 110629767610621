@import "../reset";
@import "../variables";
@import "../functions";

.member_list {
  background: #fff;
  border-radius: 20px 20px 0 0;
  overflow: scroll;
  height: calc(100vh - #{$h-header}px);

  & > li {
    padding: 7.5px 17px 6.5px;
    border-bottom: 1px solid $gray;

    & > a {
      @include flex(row, center, flex-start, nowrap);
      color: $black;
      font-size: 16px;

      & > p {
        font-size: 16px;
      }

      & > img {
        margin-right: 10px;
        max-width: 40px;
        border-radius: 50%;
        box-shadow: 0 2px 4px rgba(0, 0, 0, .09);
      }

      & > svg {
        margin-left: auto;
      }

    }

  }

}

.aside {
  position: fixed;
  top: 0;
  left: 0;
  background: #F7FCFC;
  height: 100vh;
  width: 83px;
  padding: 13px;

  ul {

    li {
      border: 2px solid transparent;
      transition: border $duration;
      border-radius: 50%;
      overflow: hidden;
      box-shadow: 0 2px 4px rgba(0, 0, 0, .09);

      &:not(:last-child) {
        margin-bottom: 10px;
      }

      a {

        & > img {
          width: 52px;
          height: 52px;
          object-fit: cover;
        }

      }

    }
  }
}

.current {
  border: 2px solid #7D2982 !important;
}
