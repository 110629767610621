@function svw($val) {
  @return ($val / $spDesignWidth*100vw);
}

@function lvw($val) {
  @return ($val / $pcDesignWidth*100vw);
}


@mixin xs() {
  @media #{map-get($breakpoints, "xs")} {
    @content;
  }
}

@mixin sm() {
  @media #{map-get($breakpoints, "sm")} {
    @content;
  }
}

@mixin tb() {
  @media #{map-get($breakpoints, "tb")} {
    @content;
  }
}

@mixin lg() {
  @media #{map-get($breakpoints, "lg")} {
    @content;
  }
}

@mixin fontSizePC($fontSize,$lineInterval) {
  font-size: #{$fontSize}px;
  line-height: $lineInterval / $fontSize;
}

@mixin fontSizeSP($fontSize,$lineInterval) {
  font-size: svw($fontSize);
  line-height: $lineInterval / $fontSize;
}

@mixin autoMargin($width,$top:0,$bottom:0) {
  @if($top = $bottom) {
    width: $width;
    margin: $top auto;
  } @else {
    width: $width;
    margin: $top auto $bottom;
  }
}

@mixin center($direction) {
  position: absolute;

  @if($direction == x) {
    left: 50%;
    transform: translate(-50%,0);
  }
  @else if($direction == y) {
    top: 50%;
    transform: translate(0,-50%);
  }
  @else if($direction == both) {
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
}

@mixin flex($flex-direction:row,$align:flex-start,$justify:flex-start,$wrap: wrap) {
  display: flex;
  flex-direction: $flex-direction;
  align-items: $align;
  justify-content: $justify;
  flex-wrap: $wrap;
}
