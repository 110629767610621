@import "reset";
@import "variables";
@import "functions";

body {
  background: $white;
  font-family: $font-default;
}

main {
  background: $light-blue;
}

a {
  color: $black;
}

.visible-lg {
  display: none;

  @include lg() {
    display: block;
  }
}

.visible-sm {
  display: none;

  @include sm() {
    display: block;
  }
}

/* メッセージ投稿レイアウト */
.message_input_wrapper {
  @include flex(row, center, center);
  width: 100vw;
  padding: 10px 7px;
  background: #F7F7F7;
}

.message_input_wrapper_invalid {
  background: #FFF5F5;
}

.message_input {
  @include flex(row, center, space-between);
  width: 100%;

  & > p {
    width: 100%;
    padding: 11px 0;
    text-align: center;
    font-size: 12px;
    color: #7B7B7B;
  }

  & > button {
    position: absolute;
    right: 0;
    transform: translate(calc(100% + 10px), 0);
    transition: transform $duration;
  }

  & > textarea, & > input{
    width: 100%;
    transition: width $duration;

    &.is-input {
      width: calc(100% - 50px);
      & + button {
        right: 10px;
        transform: translate(0, 0);
      }
    }

  }

}

.message_input_invalid {
  border-color: #E01818 !important;
}

.message_input_text {
  width: 100%;
  height: 40px;
  resize: none;
  border: 1px solid #AFAFAF;
  border-radius: 20px;
  word-break: break-all;
  white-space: break-spaces;
  padding: 10px;
  background: #F7FCFC;
  font-size: 16px;
  line-height: 1;
  -webkit-appearance: none;
  outline: none;

  &::placeholder {
    color: #ADADAD;
  }
}

.btn_circle {
  @include flex(row, center, center);
  width: 40px;
  height: 40px;
  border-radius: 8px;
  font-size:20px;
  background: #1DDAB6;

  & > * {
    color: $white;
  }

}


.btn_link {
  padding: 19px 0;
  line-height: 1;
  width: 317px;
  font-size: 20px;
  font-weight: bold;
  border-radius: 30px;
  border: 1px solid #D1D1D1;

  &.cyan {
    color: #fff;
    background: #1DDAB6;
  }

  &.gray {
    color: #747474;
    background: #fff;
  }

  &:disabled {
    opacity: 0.6;
  }
}


/* layout */
.l-main {
  background: $white;

  &.c-h { margin-top: #{$h-header}px; }
  &.c-a { margin-left: #{$w-aside}px; }
  &.c-ah {
    margin-top: #{$h-header}px;
    margin-left: #{$w-aside}px;
  }

  &.bg-transparent { background: transparent; }

}

/* error */
.error_text {
  margin-top: 13px;
  color: #E01818;
  font-size: 14px;
}

.error_message_content {
  padding: 100px 17px 0;
  font-size: 16px;
}

/* loading */
.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #1DDAB6;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #1DDAB6 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.c-red {
  color: #E01818;
}

.overflow-hidden {
  overflow: hidden;
}
